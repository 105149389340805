import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { apiContactUs } from "../utils/api";
import i18n from "../i18n/i18n";
import GoTop from "../components/goTop";

function Contact() {
  const { t } = useTranslation();
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastname] = useState("");
  const [firstName, setFirstname] = useState("");
  const [message, setMessage] = useState("");
  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);
  const [isShowSucessToast, setIsShowSucessToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const recaptchaRef = useRef();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstname":
        console.log(`length: ${value.length}`);
        if (value.length > 10) {
          setFirstnameError("format");
        } else {
          setFirstname(value);
          setFirstnameError("");
        }
        break;
      case "lastname":
        if (value.length > 10) {
          setLastnameError("format");
        } else {
          setLastname(value);
          setLastnameError("");
        }
        break;
      case "message":
        if (value.length > 500) {
          setMessageError("format");
        } else {
          setMessage(value);
          setMessageError("");
        }
        break;
      case "email":
        if (isEmail(value)) {
          setEmailError("");
          setEmail(value);
        } else {
          setEmail(value);
          setEmailError("format");
        }
        break;
      default:
        break;
    }
  };
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleGoogleRecaptchaOnChange = (value) => {
    setRecaptchaToken(value);
    setRecaptchaError("");
  };

  const handleToast = (msg) => {
    setIsShowErrorToast(true);
    setToastMsg(msg);
    setTimeout(() => {
      setIsShowErrorToast(false);
      setToastMsg("");
    }, 5000);
  };

  const handleSumitContactForm = async () => {
    if (lastName === "") {
      setLastnameError("empty");
    } else if (firstName === "") {
      setFirstnameError("empty");
    } else if (email === "") {
      setEmailError("empty");
    } else if (!isEmail(email)) {
      setEmailError("format");
    } else if (message === "") {
      setMessageError("empty");
    } else if (recaptchaToken === "") {
      setRecaptchaError("empty");
    } else {
      var sendData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        context: message,
        lang: i18n.language,
        recaptchaToken: recaptchaToken,
      };
      document.querySelector(".popup-loading").classList.add("active");
      var res = await apiContactUs(sendData);
      if (res.code === "0000") {
        document.querySelector(".popup-loading").classList.remove("active");
        clearForm();
        setIsShowSucessToast(true);
        setToastMsg(t("toast.success"));
        setTimeout(() => {
          setIsShowSucessToast(false);
          setToastMsg("");
        }, 5000);
      } else {
        document.querySelector(".popup-loading").classList.remove("active");
        handleToast(
          t("home.error.apiError", {
            message: res.message,
          })
        );
      }
    }
  };
  const clearForm = () => {
    setLastnameError("");
    setFirstnameError("");
    setEmailError("");
    setMessageError("");
    setRecaptchaError("");
    setEmail("");
    setLastname("");
    setFirstname("");
    setMessage("");
    recaptchaRef.current.reset();
  };
  return (
    <main>
      <section id="contact">
        <div className="container">
          <div className="ratio ratio-16x9 mb-4">
            <iframe
              title="googleMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.801921835981!2d103.8581653146858!3d1.2933089990567161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19a93137e8e5%3A0xb7cf6c1c3b42618!2z55ub5bm05aSn5buI!5e0!3m2!1szh-TW!2stw!4v1569818139718!5m2!1szh-TW!2stw"
              width="1000"
              height="450"
              style={{ border: "0" }}
              allowFullScreen=""
            ></iframe>
          </div>

          <div className="row g-5">
            <div className="col-lg-8">
              <h1 className="fs-4">Contact Form</h1>
              <p className="mb-4">
                {t("contact.form.desc1")} &nbsp;
                 {/* global process */}
                <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
                  {" "}
                  {process.env.REACT_APP_CONTACT_EMAIL}
                </a>
                <br />
                {t("contact.form.desc2")}
              </p>

              <form>
                <div className="row">
                  <div className="col-6">
                    <div
                      className={`form-group mb-3 ${
                        lastnameError !== "" ? "error" : ""
                      }`}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="contacts-lastname"
                        value={lastName}
                        name="lastname"
                        placeholder={t("contact.form.lastname.placeholder")}
                        onChange={handleFormChange}
                      />
                      <div className="invalid-feedback">
                        {(() => {
                          switch (lastnameError) {
                            case "empty":
                              return (
                                <p>{t("contact.form.lastname.emptyError")}</p>
                              );
                            case "format":
                              return (
                                <p>{t("contact.form.lastname.formatError")}</p>
                              );
                            default:
                              return null;
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className={`form-group mb-3 ${
                        firstnameError !== "" ? "error" : ""
                      }`}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="contacts-firstname"
                        value={firstName}
                        name="firstname"
                        placeholder={t("contact.form.firstname.placeholder")}
                        required
                        onChange={handleFormChange}
                      />
                      <div className="invalid-feedback">
                        {(() => {
                          switch (firstnameError) {
                            case "empty":
                              return (
                                <p>{t("contact.form.firstname.emptyError")}</p>
                              );
                            case "format":
                              return (
                                <p>{t("contact.form.firstname.formatError")}</p>
                              );
                            default:
                              return null;
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`form-group mb-3 ${
                    emailError !== "" ? "error" : ""
                  }`}
                >
                  <input
                    type="email"
                    className="form-control"
                    id="contacts-email"
                    value={email}
                    name="email"
                    placeholder="Email"
                    required
                    onChange={handleFormChange}
                  />
                  <div className="invalid-feedback">
                    {(() => {
                      switch (emailError) {
                        case "empty":
                          return <p>{t("contact.form.email.emptyError")}</p>;
                        case "format":
                          return <p>{t("contact.form.email.formatError")}</p>;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>

                <div
                  className={`form-group mb-3 ${
                    messageError !== "" ? "error" : ""
                  }`}
                >
                  <textarea
                    className="form-control"
                    rows="5"
                    id="contacts-message"
                    value={message}
                    name="message"
                    placeholder={t("contact.form.content.placeholder")}
                    required
                    onChange={handleFormChange}
                  ></textarea>
                  <div className="invalid-feedback">
                    {(() => {
                      switch (messageError) {
                        case "empty":
                          return <p>{t("contact.form.message.emptyError")}</p>;
                        case "format":
                          return <p>{t("contact.form.message.formatError")}</p>;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
                <div
                  className={`form-group mb-3 ${
                    recaptchaError !== "" ? "error" : ""
                  }`}
                >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    className="g-recaptcha"
                    hl={i18n.language}
                    onChange={handleGoogleRecaptchaOnChange}
                  />
                  <div className="invalid-feedback">
                    {recaptchaError !== "" && (
                      <p>{t("contact.form.recaptcha.emptyError")}</p>
                    )}
                  </div>
                </div>
                <div className="text-center text-lg-start mt-4">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    value="send_email"
                    onClick={handleSumitContactForm}
                  >
                    <i className="icon-ok"></i> Send
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={clearForm}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>

            <div className="col-lg-4 order-first order-lg-last">
              <section className="mb-5">
                <h1 className="fs-4">{t("contact.location")}</h1>
                <address>
                  <strong>
                    3 Temasek Avenue,Level 18 Centennial Tower, Singapore 039190{" "}
                  </strong>
                  <br />
                  +886-2-2658-2220
                </address>
              </section>

              <section>
                <h1 className="fs-4">Email</h1>
                <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
                  {process.env.REACT_APP_CONTACT_EMAIL}
                </a>
                <br />
              </section>
            </div>
          </div>
        </div>
      </section>
      <GoTop />
      {(isShowErrorToast || isShowSucessToast) && (
        <div
          className={
            isShowErrorToast ? "toast show error" : "toast show sucess"
          }
        >
          <strong className="mr-auto">{toastMsg}</strong>
        </div>
      )}
    </main>
  );
}

export default Contact;
