import axios from "axios";
//#region 遊戲雲API

let getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

/**
 * @param [api]
 * @param [method]
 * @param [payload]
 * @param [download]
 * @param [onUploadProgress]
 */
export const fetchDataCall = async (
  api,
  method,
  payload = null,
  download = false,
  onUploadProgress = () => {}
) => {
  // var headers = getHeaders();
  // headers.Fingerprint = await _getFingerprint();
  {
    /* global process */
  }
  var config = {
    method: method,
    url: `${process.env.REACT_APP_API_URL}/api/${api}`,
    headers: getHeaders(),
    params: null,
    data: null,
    onUploadProgress: onUploadProgress,
  };

  if (method === "get") {
    if (payload != null) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] === "") {
          delete payload[key];
        }
      });
    }
    config.params = payload;
  } else {
    config.data = payload;
  }
  if (download) {
    config.responseType = "blob";
  }

  let apiReturn = await axios
    .request(config)
    .then(async function (response) {
      if (!download) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch(function (error) {
      return error.response.data;
    });
  return apiReturn;
};
//#endregion

export const apiContactUs = (data) =>
  fetchDataCall("user/contactus", "post", data);
export const apiGetStatisicsData = () =>
  fetchDataCall("apk/statistics/result", "get");
export const apiVerifyEmail = (data) =>
  fetchDataCall(`user/verify?verify=${data}`, "get");
export const apiUploadFile = (data, onUploadProgress) =>
  fetchDataCall("apk/upload", "post", data, false, onUploadProgress); // 上傳apk
export const apiCountryStatistics = (data) =>
  fetchDataCall(`apk/statistics/country/result?country=${data}`, "get"); // 取得國家統計
export const apiVerifyRecaptchaToken = (data) =>
  fetchDataCall("apk/recaptcha", "post", data); // 上傳apk前先驗證google recaptcha token
export const apiVerifyRecaptchaAndSendMail = (data) =>
  fetchDataCall("apk/sendMail/recaptcha", "post", data); // 驗證 Recaptcha與發送驗證信
export const apiSendVerifyMail = (data) =>
  fetchDataCall("apk/sendMail", "post", data); // 發送驗證信(無Recaptcha)
export const apiCodeToVerifyEmail = (data) =>
  fetchDataCall("user/verify", "post", data); // 驗證信箱驗證碼
export const apiUploadApk = (data, onUploadProgress) =>
  fetchDataCall("apk/upload", "post", data, false, onUploadProgress); // 驗證信箱後上傳apk
